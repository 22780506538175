import React from 'react'
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

export default function ImageViewer({show, src, nextCallback, previousCallback, showCallback}) {
  if (show === false) {
    return (
      <></>
    )
  } else {
        return (
          <div className='flex justify-center items-center fixed w-full h-full z-10'>            
            <div className='absolute top-0 left-0 w-full h-full bg-black/50 z-20' onClick={() => showCallback(false)}></div>
            <CaretLeft size={64} color='white' onClick={previousCallback} className='z-30' />
            <img src={src} className='noselect z-30 h-[90%] ' alt={src} />
            <CaretRight size={64} color='white' onClick={nextCallback} className='z-30' />
        </div>
      )
    }
}
